import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UiAppService } from '@stlc/ui/services';
import { JwtAuthService, UserService } from '@stlc/user';

export const jwtAuthGuard = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const jwtService = inject(JwtAuthService);
    const appService = inject(UiAppService);
    const userService = inject(UserService);

    const hasToken = !!jwtService.accessToken;
    if (!jwtService.isTokenValid()) {
        return jwtService.refreshToken().pipe(
            map(() => jwtService.isTokenValid()),
            tap((isValid) => {
                if (!isValid) {
                    // TODO remember route that navigation was occurring on
                    if (hasToken) {
                        appService.info = 'Your session is expired. Please sign in again.';
                    }
                    userService.logout(state.url);
                    // this.router.navigateByUrl('/login');
                }
            })
        );
    }

    return of(true);
};
