import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { chain, isEmpty, isNil } from '@stlc/lodash';
import { UserService } from '@stlc/user';

export const userPreferenceQueryParamRedirect = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<UrlTree | boolean> => {
    const router = inject(Router);
    const service = inject(UserService);

    return service.getPreferenceValue$(route.data['userPreferenceQueryParamKey']).pipe(
        first(),
        map((preferences: any) => {
            // TODO: Remove "source" from saved value (observable?) so "source" isn't always omitted
            const queryParams = chain(preferences).omit('source').omitBy(isNil).valueOf();
            if (!isEmpty(queryParams) && isEmpty(route.queryParams)) {
                return router.createUrlTree([state.url], { queryParams });
            }
            return true;
        })
    );
};
